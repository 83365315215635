import { inject } from '@angular/core';
import {
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  ResolveFn
} from '@angular/router';
import { Subscription } from 'rxjs';
import { ContentItemCollection, ContentService } from '../../services/content/content.service';
import { AppStateService } from '../../services/app-state/app-state.service';

export const contentResolver: ResolveFn<any> =
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      const contentService = inject(ContentService);
      const appState = inject(AppStateService);

      let loadContent = route.data.loadContent as string[];
      if (!Array.isArray(loadContent) || !loadContent.length) {
        return undefined;
      }
      const collection = appState.get<ContentItemCollection>('contentItemCollection');
      if (collection) {
        // remove content values which are present in appState cache already
        for (const value of loadContent) {
          const [group, key ] = value.split(':');
          if (!collection[group]) { continue; }
          if (!collection[group][key]) { continue; }
          loadContent = loadContent.filter(val => val !== value)
        }
      }

      if (Array.isArray(loadContent) && loadContent.length) {
        if (route.data.loadContentASync) {
          // load NON blocking
          const sub: Subscription = contentService.getContent(loadContent).subscribe(val => sub.unsubscribe());
        } else {
          // load blocking
          return contentService.getContent(loadContent)
        }
      }
      return undefined;
    };

import { inject } from '@angular/core';
import {
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  ResolveFn
} from '@angular/router';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { Resident, ResidentService } from '../../services/resident/resident.service';
import { switchMap } from 'rxjs';
import { ResidentPreferenceData } from '@resparke/models';

export const residentResolver: ResolveFn<any> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const residentsService = inject(ResidentService);
    const appState = inject(AppStateService);
    const facilityId = appState.get<string>('currentFacilityId');
    const resident = appState.get<Resident>('currentResident');
    // only fetch resident if resident is not present in cache
    if (!resident) {
      return residentsService.getResident({
        residentId: appState.get<string>('currentResidentId'),
        facilityId,
      }).pipe(
        switchMap(resident => {
          // TEMP SOLUTION FOR NOW SO WE GET THE PREFERENCE DATA IN.
          // MAYBE ADD THE PREFERENCE IN AS A QUERY AS PART OF A STEP
          return residentsService.getResidentPreferenceData(resident.preferenceId);
        })
      )
    // check if we have a resident, if we also have residentpreference data
    } else if (!appState.get<ResidentPreferenceData>('currentResidentPreferenceData')){
      return residentsService.getResidentPreferenceData(resident.preferenceId);
    }
    return undefined;
  };

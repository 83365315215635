import { inject } from '@angular/core';
import {
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  ResolveFn
} from '@angular/router';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { Dictionary, StaticDataCollection } from 'src/app/shared/models/global';
import { Observable, forkJoin, tap } from 'rxjs';
import { ResidentService } from 'src/app/shared/services/resident/resident.service';
import { lookup } from '@resparke/utils';

export const wizardQueryResolver: ResolveFn<any> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const residentService = inject(ResidentService);
    const appState = inject(AppStateService);
    const { config } = route.data;
    if (!config) {
      return false;
    }
    const data: { query: any[] } = lookup(config, 'query');

    const queryData$: Dictionary<Observable<any>> = {};
    if (!data.query.length) {
      return undefined;
    }
    for (const query of data.query) {
      const collection = appState.get<StaticDataCollection>('staticData') || {};
      if (collection[query.name]) {
        continue;
      }
      const fnKey = query.operation as keyof ResidentService;
      queryData$[query.name] = residentService[fnKey](query.params);
    }
    // if no new queries where executed do an early return
    if (!Object.keys(queryData$).length) {
      return true;
    }
    return forkJoin(queryData$).pipe(
      tap(val => {
        const collection = appState.get<StaticDataCollection>('staticData');
        appState.setState<StaticDataCollection>('staticData', {
          ...collection,
          ...val
        })
      })
    )
  };

import { inject } from '@angular/core';
import {
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  ResolveFn
} from '@angular/router';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { combineLatest, map, Observable, of, Subscription, switchMap } from 'rxjs';
import { FileService } from '../../services/files/file.service';
import { ResidentPhoto } from '../../models/file.model';

export const residentPhotosResolver: ResolveFn<any> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const fileService = inject(FileService);
    const appState = inject(AppStateService);
    const facilityId = appState.get<string>('currentFacilityId');
    const residentId = appState.get<string>('currentResidentId');

    const photos = appState.get<ResidentPhoto[]>('residentPhotos');
    // this will prevent from fetching the photos again unless there is a page refresh
    if (photos && photos.length > 0) {
      return undefined;
    }
    if (residentId) {
      // fetch resident photos asynchronously
      fileService.getResidentPhotos({ residentId })
      .pipe(
        switchMap(photos => {
          if (!photos.length) {
            return of([])
          }
          const obs$: Observable<File>[] = [];
          for (const photo of photos) {
            let { filename } = photo;
            if (photo.hasThumbnail) {
              filename = filename.replace('.jpeg', '_thumbnail.jpeg');
            }
            const key = `facilities/${facilityId}/residents/${residentId}/${filename}`;
            obs$.push(fileService.getSignedUrl({ key, action: 'GET' })
              .pipe(
                switchMap(url => {
                  return fileService.getFileBuffer(url);
                })
              ))
          }
          return combineLatest(obs$).pipe(
            // copy buffer over to photos
            map(files => {
              return files.map((file, index) => {
                const photo = photos.at(index);
                return {
                  ...photo,
                  file,
                }
              })
            })
          )
        })
      )
      .subscribe(photos => {
        appState.set<ResidentPhoto[]>('residentPhotos', photos)
      });
    }
    return undefined;
  };
